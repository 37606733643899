import React, { Component } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Loading from './components/utils/Loading';
import AdminHome from './components/admin/AdminHome';
import AdminLogin from './components/admin/AdminLogin';
import SetUpTickets from './components/admin/SetUpTickets';
import SetUpQuestions from './components/admin/SetUpQuestions';
import SetUpGame from './components/admin/SetUpGame';
import SetUpTeamVariables from './components/admin/SetUpTeamVariables';
import SetUpRulesAndRegs from './components/admin/SetUpRulesAndRegs';
import SetUpTicketEmail from './components/admin/SetUpTicketEmail';
import LoginVariables from './components/admin/SetUpLoginVariables';
import SetUpWebHooks from "./components/admin/SetUpWebHooks";
import WebHooksDocumentation from "./components/admin/WebHooksDocumentation";
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './base';

function PrivateRoute({ authenticated, children }) {
    return authenticated === true ? children : <Navigate to="/adminlogin" />;
}

class App extends Component {
    constructor(props) {
        super(props);
        this.setCurrentUser = this.setCurrentUser.bind(this);
        this.state = {
            authenticated: false,
            currentUser: null,
            loading: true
        };
    }

    setCurrentUser(user) {
        if (user) {
            this.setState({
                currentUser: user,
                authenticated: true
            });
        } else {
            this.setState({
                currentUser: null,
                authenticated: false
            });
        }
    }

    componentDidMount() {
        this.removeAuthListener = onAuthStateChanged(auth, (user) => {
            if (user) {
                this.setState({
                    authenticated: true,
                    currentUser: user,
                    loading: false,
                });
            } else {
                this.setState({
                    authenticated: false,
                    currentUser: null,
                    loading: false,
                });
            }
        });
    }

    componentWillUnmount() {
        this.removeAuthListener();
    }

    render() {
        if (this.state.loading === true) {
            return <Loading loading={this.state.loading} />;
        }
        return (
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/adminlogin"
                        element={<AdminLogin setCurrentUser={this.setCurrentUser} user={this.state.currentUser} />}
                    />

                    <Route
                        path="/admin"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <AdminHome />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/setupgame"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <SetUpGame currentUser={this.state.currentUser} />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/setupquestions"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <SetUpQuestions />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/setuptickets"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <SetUpTickets />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/setupteamvariables"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <SetUpTeamVariables />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/setuprulesandregs"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <SetUpRulesAndRegs />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/setupticketemail"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <SetUpTicketEmail />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/setuploginvariables"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <LoginVariables />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/webhooks"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <SetUpWebHooks />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/webhooksdocumentation"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <WebHooksDocumentation />
                            </PrivateRoute>
                        }
                    />

                    <Route path="*" element={<Navigate to="/adminlogin" />} />
                </Routes>
            </BrowserRouter>
        );
    }
}

export default App;
