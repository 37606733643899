import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import { getDatabase, ref, onValue, set, off } from 'firebase/database';
import { app } from '../../base';
import '../../styles/css/AdminMain.css';
import { showSuccessPopup, showErrorPopup, showInfoPopup } from '../utils/SwalConfig';

class SetUpTicketEmail extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            helpText: '',
            subjectLine: '',
            emailBackgroundImage: '',
            teamIconImage: '',
            loading: true,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    componentWillUnmount() {
        const db = getDatabase(app);
        off(ref(db, 'emailVariables/subjectLine'), this.subjectLineListener);
        off(ref(db, 'emailVariables/helpText'), this.helpTextListener);
        off(ref(db, 'emailVariables/teamIconImage'), this.teamIconImageListener);
        off(ref(db, 'emailVariables/emailBackgroundImage'), this.emailBackgroundImageListener);
    }

    componentDidMount() {
        const db = getDatabase(app);
        const subjectLineRef = ref(db, 'emailVariables/subjectLine');
        const helpTextRef = ref(db, 'emailVariables/helpText');
        const teamIconImageRef = ref(db, 'emailVariables/teamIconImage');
        const emailBackgroundImageRef = ref(db, 'emailVariables/emailBackgroundImage');

        this.subjectLineListener = onValue(subjectLineRef, (snapshot) => {
            this.setState({ subjectLine: snapshot.val() || '' });
        });
        this.helpTextListener = onValue(helpTextRef, (snapshot) => {
            this.setState({ helpText: snapshot.val() || '' });
        });
        this.teamIconImageListener = onValue(teamIconImageRef, (snapshot) => {
            this.setState({ teamIconImage: snapshot.val() || '' });
        });
        this.emailBackgroundImageListener = onValue(emailBackgroundImageRef, (snapshot) => {
            this.setState({ emailBackgroundImage: snapshot.val() || '' });
        });

        this.setState({ loading: false });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleImageChange(event) {
        const name_of_file = event.target.name;
        const file_to_update = event.target.files[0];
        this.setState({ loading: true });
        const vm = this;
        this.UploadImage.upload_file(file_to_update).then(res => {
            vm.setState({ loading: false });
            if (res.error) {
                showInfoPopup('Image cannot be uploaded', res.error);
            } else if (res.imageUrl) {
                vm.setState({
                    [name_of_file]: res.imageUrl,
                }, () => {
                    vm[name_of_file].value = "";
                });
            } else {
                showInfoPopup('Image cannot be uploaded', 'Something went wrong, please re-upload your image and try again!');
            }
        });
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    handleSubmit(event) {
        event.preventDefault();
        const { helpText, subjectLine, teamIconImage, emailBackgroundImage } = this.state;
        if (!helpText || !subjectLine || !teamIconImage || !emailBackgroundImage) {
            showInfoPopup('Missing fields', 'Missing Required Fields!');
            return;
        }
        this.setState({ loading: true });
        const updateRulesObject = {
            helpText,
            subjectLine,
            teamIconImage,
            emailBackgroundImage
        };
        const db = getDatabase(app);
        set(ref(db, "emailVariables/"), updateRulesObject)
            .then(() => {
                this.setState({ loading: false });
                showSuccessPopup('Rules and Regs Updated!', 'The email variables have been successfully updated.');
            })
            .catch((err) => {
                this.setState({ loading: false });
                showErrorPopup('There was some error!', 'Try again and if the problem persists try logging out and logging back in.');
                console.error(err);
            });
    }

    render() {
        let helpText = this.state.helpText;
        let subjectLine = this.state.subjectLine;
        if (typeof helpText === "object") {
            helpText = "";
        }
        if (typeof subjectLine === "object") {
            subjectLine = "";
        }
        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <p className="admin-header-text" style={{ marginBottom: 0 }}>Email Branding</p>
                    <p className="admin-subheader-text">This is where you can edit the email sent to fans</p>
                    <div className="container-out">
                        <div className="admin-form-box">
                            <form onSubmit={this.handleSubmit} id="create-email-form">
                                <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{ marginBottom: 20 }}><span className="fa fa-arrow-circle-o-up" /> Update</button>
                                <div className="row col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="subjectLine">Email Subject Line</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>This is the subject line that your fans will see when they receive their winning emails</p>
                                        <input id="subjectLine" name="subjectLine" className="form-control" value={subjectLine} onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-3">
                                        <label htmlFor="helpText">Help Text</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>This is where you can tell fans where to contact you with issues. (Example: Having issues? Email info@trivia.com for help.)</p>
                                        <textarea style={{ minHeight: '100px' }} id="helpText" name="helpText" className="form-control" value={helpText} onChange={this.handleChange} />
                                    </div>
                                    <div className="form-group col-md-3" align="center">
                                        <img src={this.state.teamIconImage} width="100px" height="100px" alt="" />
                                        <br />
                                        <label htmlFor="teamIconImage">Team Logo<br />(200px X 200px)</label>
                                        <div className="form-group">
                                            <input style={{ display: 'none' }} id="teamIconImage" name="teamIconImage" type="file" ref={input => { this.teamIconImage = input; }} onChange={this.handleImageChange} />
                                            <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('teamIconImage').click()} />
                                        </div>
                                    </div>
                                    <div className="form-group col-md-3" align="center">
                                        <img src={this.state.emailBackgroundImage} width="auto" height="100px" alt="" />
                                        <br />
                                        <label htmlFor="backgroundImage">Email Header Image<br />(700px X 400px)</label>
                                        <div className="form-group">
                                            <input style={{ display: 'none' }} id="emailBackgroundImage" name="emailBackgroundImage" type="file" ref={input => { this.emailBackgroundImage = input; }} onChange={this.handleImageChange} />
                                            <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('emailBackgroundImage').click()} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SetUpTicketEmail;
