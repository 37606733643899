import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { getDatabase, ref, onValue, update } from 'firebase/database';
import '../../styles/css/AdminMain.css';
import RichTextEditor from 'html-verify-react-rte';
import { showSuccessPopup, showErrorPopup, showInfoPopup } from '../utils/SwalConfig';

const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'LINK_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' },
    ],
};

class SetUpRulesAndRegs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rulesAndRegsText: RichTextEditor.createEmptyValue(),
            howToPlayText: RichTextEditor.createEmptyValue(),
            rulesPopUpText: RichTextEditor.createEmptyValue(),
            rulesShowInAppPopUpText: RichTextEditor.createEmptyValue(),
            didNotCheckRulesAndRegsBody2: RichTextEditor.createEmptyValue(),
            secondaryMandatoryCheckboxText: RichTextEditor.createEmptyValue(),
            rulesPopUpHeader: "",
            rulesShowInAppPopUpHeader: "",
            rulesInAppButtonText: "",
            rulesAndRegsLink: "",
            howToPlayLink: "",
            tenantRules: null,
            loading: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const db = getDatabase();
        const tenantRulesRef = ref(db, 'tenantRules');

        onValue(tenantRulesRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                this.setState({
                    howToPlayText: data.howToPlayText ? RichTextEditor.createValueFromString(data.howToPlayText, 'html') : RichTextEditor.createEmptyValue(),
                    howToPlayLink: data.howToPlayLink || "",
                    rulesPopUpText: data.rulesPopUpText ? RichTextEditor.createValueFromString(data.rulesPopUpText, 'html') : RichTextEditor.createEmptyValue(),
                    rulesShowInAppPopUpText: data.rulesShowInAppPopUpText ? RichTextEditor.createValueFromString(data.rulesShowInAppPopUpText, 'html') : RichTextEditor.createEmptyValue(),
                    rulesShowInApp: data.rulesShowInApp || false,
                    rulesAndRegsText: data.rulesAndRegsText ? RichTextEditor.createValueFromString(data.rulesAndRegsText, 'html') : RichTextEditor.createEmptyValue(),
                    rulesPopUpHeader: data.rulesPopUpHeader || "",
                    rulesInAppButtonText: data.rulesInAppButtonText || "",
                    rulesShowInAppPopUpHeader: data.rulesShowInAppPopUpHeader || "",
                    noRulesAndRegsHeader: data.noRulesAndRegsHeader || "",
                    noRulesAndRegsBody: data.noRulesAndRegsBody || "",
                    didNotCheckRulesAndRegsBody2: data.didNotCheckRulesAndRegsBody2 ? RichTextEditor.createValueFromString(data.didNotCheckRulesAndRegsBody2, 'html') : RichTextEditor.createEmptyValue(),
                    secondaryMandatoryCheckboxText: data.secondaryMandatoryCheckboxText ? RichTextEditor.createValueFromString(data.secondaryMandatoryCheckboxText, 'html') : RichTextEditor.createEmptyValue(),
                    didNotCheckRulesAndRegsHeader2: data.didNotCheckRulesAndRegsHeader2 || "",
                    turnOnSecondMandatoryCheckbox: data.turnOnSecondMandatoryCheckbox || false,
                    advanced: data.turnOnSecondMandatoryCheckbox || data.rulesShowInApp || data.howToPlayLink,
                    loading: false
                });
            } else {
                this.setState({ loading: false });
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const {
            rulesAndRegsText,
            howToPlayLink,
            rulesPopUpText,
            howToPlayText,
            rulesShowInAppPopUpText,
            rulesPopUpHeader,
            rulesShowInAppPopUpHeader,
            rulesInAppButtonText,
            rulesShowInApp,
            noRulesAndRegsHeader,
            noRulesAndRegsBody,
            didNotCheckRulesAndRegsBody2,
            secondaryMandatoryCheckboxText,
            didNotCheckRulesAndRegsHeader2,
            turnOnSecondMandatoryCheckbox
        } = this.state;

        if (rulesInAppButtonText && rulesInAppButtonText.length > 18) {
            showInfoPopup("Input Error", "Button Text Cannot Be Longer Than 18 Characters");
            return;
        }

        const updateRulesObject = {
            rulesAndRegsText: rulesAndRegsText.toString('html'),
            howToPlayLink: howToPlayLink || "",
            howToPlayText: howToPlayText.toString('html'),
            rulesPopUpText: rulesPopUpText.toString('html'),
            rulesPopUpHeader: rulesPopUpHeader || "",
            rulesShowInAppPopUpText: rulesShowInAppPopUpText.toString('html'),
            rulesShowInAppPopUpHeader: rulesShowInAppPopUpHeader || "",
            rulesInAppButtonText: rulesInAppButtonText || "",
            rulesShowInApp: rulesShowInApp || false,
            noRulesAndRegsHeader: noRulesAndRegsHeader || "",
            noRulesAndRegsBody: noRulesAndRegsBody || "",
            didNotCheckRulesAndRegsBody2: didNotCheckRulesAndRegsBody2.toString('html'),
            secondaryMandatoryCheckboxText: secondaryMandatoryCheckboxText.toString('html'),
            didNotCheckRulesAndRegsHeader2: didNotCheckRulesAndRegsHeader2 || "",
            turnOnSecondMandatoryCheckbox: turnOnSecondMandatoryCheckbox || false
        };

        this.setState({ loading: true });

        const db = getDatabase();
        const tenantRulesRef = ref(db, 'tenantRules');

        update(tenantRulesRef, updateRulesObject)
            .then(() => {
                this.setState({ loading: false });
                showSuccessPopup('Rules and Regs Updated!', 'Rules and regulations have been successfully updated.');
            })
            .catch((err) => {
                this.setState({ loading: false });
                showErrorPopup('There was some error!', 'Try again and if the problem persists try logging out and logging back in');
                console.log(err);
            });
    }

    handleRichTextChange = (name, text) => {
        this.setState({ [name]: text });
    }

    handleChange(evt) {
        const { name, type, value, checked } = evt.target;
        this.setState({ [name]: type === 'checkbox' ? checked : value });
    }

    render() {
        const {
            rulesAndRegsText,
            rulesPopUpHeader,
            rulesPopUpText,
            rulesInAppButtonText,
            rulesShowInAppPopUpHeader,
            rulesShowInAppPopUpText,
            howToPlayLink,
            howToPlayText,
            noRulesAndRegsHeader,
            noRulesAndRegsBody,
            advanced,
            rulesShowInApp,
            loading,
            didNotCheckRulesAndRegsHeader2,
            turnOnSecondMandatoryCheckbox,
            didNotCheckRulesAndRegsBody2,
            secondaryMandatoryCheckboxText
        } = this.state;

        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <div className="container-out" style={{ width: '45%', float: 'left' }}>
                        <div className="admin-form-box">
                            <form onSubmit={this.handleSubmit} id="create-game-form">
                                <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{ marginBottom: '20px' }}><span className="fa fa-arrow-circle-o-up" /> Update</button>
                                <div className="form-group">
                                    <label htmlFor="rulesAndRegsText">Rules And Regs Text</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>This text will appear AFTER the mandatory confirm rules and regs button on the login form<br /><strong>Example:</strong> Welcome! To play, please confirm that you agree with the rules and regs</p>
                                    <RichTextEditor id="rulesAndRegsText" name="rulesAndRegsText" toolbarConfig={toolbarConfig} value={rulesAndRegsText} onChange={(text) => this.handleRichTextChange('rulesAndRegsText', text)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="noRulesAndRegsHeader">No Rules And Regs Header</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>This text will appear if Rules & Regs is not checked</p>
                                    <input id="noRulesAndRegsHeader" name="noRulesAndRegsHeader" type="text" className="form-control" value={noRulesAndRegsHeader} onChange={this.handleChange} placeholder="Missing Info" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="noRulesAndRegsBody">No Rules And Regs Body</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>This text will appear if Rules & Regs is not checked</p>
                                    <input id="noRulesAndRegsBody" name="noRulesAndRegsBody" type="text" className="form-control" value={noRulesAndRegsBody} onChange={this.handleChange} placeholder="Please Agree To Rules And Regulations" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="showAdvancedSettings">Advanced</label>
                                    <br />
                                    <input type="checkbox" checked={advanced} id="advanced" name="advanced" onChange={this.handleChange} />
                                </div>
                                {advanced &&
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="howToPlayLink">How To Play Link (Optional)</label>
                                            <p className="text-muted2" style={{ fontSize: '10px' }}>This is where you can teach fans how to play</p>
                                            <input id="howToPlayLink" name="howToPlayLink" type="url" className="form-control" value={howToPlayLink} onChange={this.handleChange} placeholder="https://ourgameplay.com" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="howToPlayText">How To Play Text (replaces link if filled)</label>
                                            <p className="text-muted2" style={{ fontSize: '10px' }}>This is where you can teach fans how to play</p>
                                            <RichTextEditor id="howToPlayText" name="howToPlayText" toolbarConfig={toolbarConfig} value={howToPlayText} onChange={(text) => this.handleRichTextChange("howToPlayText", text)} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="rulesPopUpHeader">Rules & Regs Pop Up Header (Optional)</label>
                                            <p className="text-muted2" style={{ fontSize: '10px' }}>The header of the in-app pop up containing rules</p>
                                            <input id="rulesPopUpHeader" name="rulesPopUpHeader" type="text" className="form-control" value={rulesPopUpHeader} onChange={this.handleChange} placeholder="Rules & Regs" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="rulesPopUpText">Rules & Regs Pop Up Text</label>
                                            <p className="text-muted2" style={{ fontSize: '10px' }}>The text of the in-app pop up. When this is filled in it will replace the rules and regs link and the text here will show up in a pop up.</p>
                                            <RichTextEditor id="rulesPopUpText" name="rulesPopUpText" toolbarConfig={toolbarConfig} value={rulesPopUpText} onChange={(text) => this.handleRichTextChange("rulesPopUpText", text)} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="rulesShowInApp">Show Rules And Regs After Sign Up Screen</label>
                                            <br />
                                            <input type="checkbox" checked={rulesShowInApp} id="rulesShowInApp" name="rulesShowInApp" onChange={this.handleChange} />
                                        </div>
                                        {rulesShowInApp &&
                                            <>
                                                <div className="form-group">
                                                    <label htmlFor="rulesInAppButtonText">Button Text</label>
                                                    <p className="text-muted2" style={{ fontSize: '10px' }}>The text of the button that links to Rules & Regs</p>
                                                    <input id="rulesInAppButtonText" name="rulesInAppButtonText" type="text" className="form-control" value={rulesInAppButtonText} onChange={this.handleChange} placeholder="Rules & Regs" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="rulesShowInAppPopUpHeader">Pop Up After Sign Up (Optional)</label>
                                                    <p className="text-muted2" style={{ fontSize: '10px' }}>The header of the in-app pop up</p>
                                                    <input id="rulesShowInAppPopUpHeader" name="rulesShowInAppPopUpHeader" type="text" className="form-control" value={rulesShowInAppPopUpHeader} onChange={this.handleChange} placeholder="Rules & Regs" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="rulesShowInAppPopUpText">Rules & Regs Pop Up Text</label>
                                                    <p className="text-muted2" style={{ fontSize: '10px' }}>The body text of the pop up</p>
                                                    <RichTextEditor id="rulesShowInAppPopUpText" name="rulesShowInAppPopUpText" toolbarConfig={toolbarConfig} value={rulesShowInAppPopUpText} onChange={(text) => this.handleRichTextChange("rulesShowInAppPopUpText", text)} />
                                                </div>
                                            </>
                                        }
                                        <div className="form-group">
                                            <label htmlFor="turnOnSecondMandatoryCheckbox">Second Mandatory Rules & Regs Checkbox</label>
                                            <br/>
                                            <input type="checkbox" checked={turnOnSecondMandatoryCheckbox} id="turnOnSecondMandatoryCheckbox" name="turnOnSecondMandatoryCheckbox" onChange={this.handleChange}/>
                                        </div>
                                        {turnOnSecondMandatoryCheckbox &&
                                            <>
                                                <div className="form-group">
                                                    <label htmlFor="secondaryMandatoryCheckboxText">Rules And Regs Text</label>
                                                    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This text will appear AFTER the second mandatory confirm rules and regs button on the login form<br/><strong>Example:</strong> Welcome! To play, please confirm that you agree with the second rules and regs</p>
                                                    <RichTextEditor
                                                        toolbarConfig={toolbarConfig}
                                                        id="secondaryMandatoryCheckboxText"
                                                        name="secondaryMandatoryCheckboxText"
                                                        value={secondaryMandatoryCheckboxText}
                                                        onChange={(text) => this.handleRichTextChange("secondaryMandatoryCheckboxText", text)}
                                                        placeholder=""
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="didNotCheckRulesAndRegsHeader2">Did Not Check Rules And Regs Header</label>
                                                    <p className="text-muted2" style={{fontSize:'10px'}}>The header of the error when the Rules & Regs checkbox isn't error</p>
                                                    <input id="didNotCheckRulesAndRegsHeader2" name="didNotCheckRulesAndRegsHeader2" type="text" className="form-control" value={didNotCheckRulesAndRegsHeader2} onChange={this.handleChange} placeholder="Input Error"/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="didNotCheckRulesAndRegsBody2">Did Not Check Rules And Regs Body</label>
                                                    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is the body message of the pop up</p>
                                                    <RichTextEditor
                                                        toolbarConfig={toolbarConfig}
                                                        id="didNotCheckRulesAndRegsBody2"
                                                        name="didNotCheckRulesAndRegsBody2"
                                                        value={didNotCheckRulesAndRegsBody2}
                                                        onChange={(text) => this.handleRichTextChange("didNotCheckRulesAndRegsBody2", text)}
                                                        placeholder=""
                                                    />
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                            </form>
                        </div>
                    </div>
                    <div className="container-out" style={{ width: '45%', float: 'right', marginRight: '20px' }}>
                        <div className="admin-form-box" style={{ border: '1px solid black', backgroundColor: 'white' }}>
                            <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Why do I need rules & regulations?</p>
                            <p className="text-muted2">While each state, country, and province is different, you will want to make sure you are compliant with all contest rules and laws.  It is your responsibility to update, maintain, & host your rules and regulations tab.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SetUpRulesAndRegs;
