import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import { getDatabase, ref, onValue, push, remove, set } from 'firebase/database';
import { app } from '../../base';
import { showSuccessPopup, showErrorPopup, showInfoPopup } from '../utils/SwalConfig';
import '../../styles/css/AdminMain.css';
import Dropzone from 'react-dropzone';
import RichTextEditor from 'html-verify-react-rte';

class SetUpTickets extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            ticketList: [],
            editingTicket: false,
            rewardToEdit: null,
            imageUrl: null,
            modal: false,
            emailImage: null,
            rewardName: "",
            rewardsList: [],
            rewardLink: "",
            rewardLinkButtonText: "",
            rewardDescription: RichTextEditor.createEmptyValue(),
            editTicketId: "",
            editTicketEmailImage: '',
            editTicketPictureUnderScratcher: '',
            revealImagePreview: {},
            emailImagePreview: {},
            loading: true,
            files: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    onDrop(files, rejected, myArgument) {
        if (rejected.length > 0) {
            showInfoPopup('Image cannot be uploaded', 'Make sure the image is less than 2mbs and it is an accepted file type');
            return;
        }
        this.setState({ loading: true });

        this.UploadImage.upload_file(files[0]).then(res => {
            this.setState({ loading: false });
            if (res.error) {
                showInfoPopup('Image cannot be uploaded', res.error);
            } else if (res.imageUrl) {
                const nameToUpdate = myArgument + "Preview";
                const fileToUpdate = files[0];
                this.setState({
                    emailImage: res.imageUrl,
                    [nameToUpdate]: {
                        fileToUpdate,
                        preview: URL.createObjectURL(fileToUpdate)
                    }
                });
            } else {
                showInfoPopup('Image cannot be uploaded', 'Something went wrong, please re-upload your image and try again!');
            }
        });
    }

    onChangeDescription = (rewardDescription) => {
        this.setState({
            rewardDescription,
        });
    }

    componentDidMount() {
        const db = getDatabase(app);
        const rewardsListRef = ref(db, 'rewardsList');

        this.rewardsListListener = onValue(rewardsListRef, (snapshot) => {
            const rewardsList = [];
            snapshot.forEach((childSnapshot) => {
                rewardsList.push({ key: childSnapshot.key, ...childSnapshot.val() });
            });
            this.setState({ rewardsList, loading: false });
        });
    }

    componentWillUnmount() {
        const db = getDatabase(app);
        const rewardsListRef = ref(db, 'rewardsList');
        off(rewardsListRef, this.rewardsListListener);

        // Make sure to revoke the data uris to avoid memory leaks
        URL.revokeObjectURL(this.state.revealImagePreview.preview);
        URL.revokeObjectURL(this.state.emailImagePreview.preview);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.loading) {
            return;
        }

        const rewardName = this.state.rewardName || "";
        if (rewardName.length > 50) {
            showInfoPopup('Oh uh!', 'Reward name cannot be longer than 50 characters!');
            return;
        }
        const rewardLink = this.state.rewardLink || "";
        const rewardLinkButtonText = this.state.rewardLinkButtonText || "";
        let rewardDescription = this.state.rewardDescription.toString('html');
        if (rewardDescription === "<p><br></p>") {
            rewardDescription = "";
        }
        const emailImage = this.state.emailImage;
        if (!rewardName || !rewardDescription || !emailImage) {
            showInfoPopup('Not all fields filled out!', 'Please enter them and try again');
            return;
        }
        const rewardToSave = {
            rewardName,
            emailImage,
            rewardLink,
            rewardLinkButtonText,
            description: rewardDescription
        };

        this.setState({ loading: true });
        const db = getDatabase(app);
        const rewardsListRef = ref(db, 'rewardsList');
        const vm = this;

        if (!vm.state.editingTicket) {
            push(rewardsListRef, rewardToSave)
                .then(() => {
                    vm.setState({
                        rewardDescription: RichTextEditor.createEmptyValue(),
                        fileEmailImage: null,
                        emailImage: null,
                        rewardName: "",
                        rewardLink: "",
                        rewardLinkButtonText: "",
                        loading: false,
                        modal: false
                    });
                    showSuccessPopup('Winning Ticket Created!', 'The prize has been successfully created.');
                })
                .catch((err) => {
                    vm.setState({ loading: false });
                    showErrorPopup('There was some error!', 'Try again and if the problem persists try logging out and logging back in.');
                    console.error(err);
                });
        } else {
            const selectedRewardId = vm.state.rewardToEdit.key;
            set(ref(db, `rewardsList/${selectedRewardId}`), rewardToSave)
                .then(() => {
                    vm.setState({
                        rewardDescription: RichTextEditor.createEmptyValue(),
                        fileEmailImage: null,
                        rewardName: "",
                        emailImage: null,
                        rewardLink: "",
                        rewardLinkButtonText: "",
                        rewardToEdit: null,
                        revealImagePreview: {},
                        emailImagePreview: {},
                        editingTicket: false,
                        modal: false,
                        loading: false
                    });
                    showSuccessPopup('Ticket Edited!', 'The ticket has been successfully edited.');
                })
                .catch((err) => {
                    vm.setState({ loading: false });
                    showErrorPopup('There was some error!', 'Try again and if the problem persists try logging out and logging back in.');
                    console.error(err);
                });
        }
    }

    editTicket(event) {
        event.preventDefault();
        const array = this.state.rewardsList;
        const reward = array[event.target.value];
        this.setState({
            modal: true,
            rewardName: reward.rewardName,
            rewardLink: reward.rewardLink,
            rewardDescription: RichTextEditor.createValueFromString(reward.description, 'html'),
            rewardLinkButtonText: reward.rewardLinkButtonText,
            editTicketEmailImage: reward.emailImage,
            rewardToEdit: reward,
            editingTicket: true,
        });
    }

    deleteTicket(event) {
        event.preventDefault();
        const array = this.state.rewardsList;
        const reward = array[event.target.value];
        showInfoPopup('Delete Ticket?', 'Are you sure you want to do this? You will no longer be able to use this ticket in any new games')
        .then((result) => {
            if (result.value) {
                const db = getDatabase(app);
                remove(ref(db, 'rewardsList/' + reward.key))
                    .then(() => {
                        showSuccessPopup('Ticket Deleted!', 'The ticket has been successfully deleted.');
                    })
                    .catch((err) => {
                        showErrorPopup('There was some error!', 'Try again and if the problem persists try logging out and logging back in.');
                        console.error(err);
                    });
            }
        });
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
            editingTicket: false,
        });
    }

    render() {
        const winningTicketList = this.state.rewardsList;
        const emailImagePreview = this.state.emailImagePreview;

        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <div className="card">
                        <div className="card-body">
                            <p className="admin-header-text" style={{ marginBottom: 0 }}>Prizes</p>
                            <p className="admin-subheader-text">These are prizes fans will receive when they win</p>
                            <button className="btn btn-primary btn-lg create-prize-button" style={{ fontSize: 20, marginLeft: 20 }} onClick={this.toggle}>Add Prize</button>
                            <div className="admin-grid-container four-columns" style={{ marginTop: 20 }}>
                                {
                                    winningTicketList.map((item, i) => (
                                        <div key={i} className="card">
                                            <div className="card-body" align="center">
                                                <p style={{ marginTop: 5 }}>{item.rewardName}</p>
                                                <p>
                                                    <img width="80%" height="auto" src={item.emailImage} alt="" />
                                                </p>
                                                <button className="btn btn-primary btn-lg edit-button" style={{ marginRight: 5, marginBottom: 10 }} onClick={this.editTicket.bind(this)} value={i}><span className="fa fa-ellipsis-v" /> Edit</button>
                                                <button className="btn btn-primary btn-lg delete-button" style={{ marginBottom: 10 }} onClick={this.deleteTicket.bind(this)} value={i}><span className="fa fa-trash-o" /> Delete</button>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} style={{ width: '90%' }} id="myModal">
                    <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                    <ModalHeader toggle={this.toggle}>Add Prize</ModalHeader>
                    <ModalBody>
                        <div className="container-out">
                            <div className="question-box question-form">
                                <form className="pl-3 pr-3" onSubmit={this.handleSubmit} id="create-email-form">
                                    <div className="form-group">
                                        <label htmlFor="rewardName">Prize Name</label>
                                        <input id="rewardName" name="rewardName" type="text" className="form-control" value={this.state.rewardName} onChange={this.handleChange} placeholder="My Sweet Prize" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="rewardLink">Prize Link (Optional)</label>
                                        <input id="rewardLink" name="rewardLink" type="url" className="form-control" value={this.state.rewardLink} onChange={this.handleChange} placeholder="http://my_sweet_prize_link.com" />
                                    </div>
                                    <div style={{ display: this.state.rewardLink ? "block" : "none" }} className="form-group">
                                        <label htmlFor="rewardLinkButtonText">Prize Link Button Text (Optional)</label>
                                        <input id="rewardLinkButtonText" name="rewardLinkButtonText" type="text" className="form-control" value={this.state.rewardLinkButtonText} onChange={this.handleChange} placeholder="Reward Link" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="rewardDescription">Prize Description: </label>
                                        <RichTextEditor id="rewardDescription" name="rewardDescription" type="text" value={this.state.rewardDescription} onChange={this.onChangeDescription} placeholder="My Sweet Prize Description" />
                                    </div>
                                    <div className="form-group" align="center">
                                        <label htmlFor="rewardEmailImage" style={{ width: '100%' }}>Prize Email Image</label>
                                        <img src={this.state.editTicketEmailImage} width="auto" height="100px" style={{ display: this.state.editingTicket ? '' : 'none' }} alt="" />
                                        <Dropzone
                                            className="dropzone dz-clickable"
                                            accept="image/*"
                                            onDrop={(accepted, rejected) => { this.onDrop(accepted, rejected, 'emailImage') }}
                                            multiple={false}
                                            maxSize={2200000}>
                                            <div className="dz-message needsclick">
                                                <span className="fa fa-cloud-upload text-muted" style={{ display: emailImagePreview.preview ? 'none' : '' }} />
                                                <h3 style={{ display: emailImagePreview.preview ? 'none' : '' }}>Drop files here or click to upload.</h3>
                                                <img
                                                    style={{ display: emailImagePreview.preview ? '' : 'none' }}
                                                    src={emailImagePreview.preview}
                                                    width="100px"
                                                    height="auto"
                                                    alt="Drop your image here"
                                                />
                                            </div>
                                        </Dropzone>
                                    </div>
                                    <div className="form-group text-center">
                                        <button className="btn btn-primary btn-lg submit-button" id="submitButton">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default SetUpTickets;
