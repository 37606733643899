import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { getDatabase, ref, onValue, update } from 'firebase/database';
import '../../styles/css/AdminMain.css';
import { showSuccessPopup, showErrorPopup, showInfoPopup } from '../utils/SwalConfig';
import RichTextEditor from 'html-verify-react-rte';
import { isObjectEmpty, validateEmail } from "../utils/HelpfulFunction";
const google = window.google;
let geocoder = new google.maps.Geocoder();
const isValidDomain = require('is-valid-domain');

class SetUpLoginVariables extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tenantVariables: {}
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
    }

    componentDidMount() {
        const db = getDatabase();
        const tenantVariablesRef = ref(db, 'tenantVariables');
        onValue(tenantVariablesRef, (snapshot) => {
            const tenantVariables = snapshot.val();
            let advanced = false;
            if (tenantVariables.doNotCollectEmail || tenantVariables.sanitizeEmails || tenantVariables.allowList || tenantVariables.blockList || tenantVariables.collectDistance || tenantVariables.noMandatoryTermsAndConditions || (tenantVariables.collectZipCode && tenantVariables.doNotVerifyAmericanZipCode) || tenantVariables.keepFansLoggedIn || tenantVariables.gdprCompliance) {
                advanced = true;
            }
            if (tenantVariables) {
                let gdprComplianceMessage = tenantVariables.gdprComplianceMessage;
                if (!gdprComplianceMessage) {
                    gdprComplianceMessage = RichTextEditor.createEmptyValue();
                } else {
                    gdprComplianceMessage = RichTextEditor.createValueFromString(gdprComplianceMessage, 'html');
                }
                let notAcceptableLocationMessage = tenantVariables.notAcceptableLocationMessage;
                if (!notAcceptableLocationMessage) {
                    notAcceptableLocationMessage = RichTextEditor.createEmptyValue();
                } else {
                    notAcceptableLocationMessage = RichTextEditor.createValueFromString(notAcceptableLocationMessage, 'html');
                }
                let promotionText = tenantVariables.promotionText;
                let promotionTextTwo = tenantVariables.promotionTextTwo;
                let promotionTextThree = tenantVariables.promotionTextThree;
                let promotionTextFour = tenantVariables.promotionTextFour;
                if (!promotionText) {
                    promotionText = RichTextEditor.createEmptyValue();
                } else {
                    promotionText = RichTextEditor.createValueFromString(promotionText, 'html');
                }
                if (!promotionTextTwo) {
                    promotionTextTwo = RichTextEditor.createEmptyValue();
                } else {
                    promotionTextTwo = RichTextEditor.createValueFromString(promotionTextTwo, 'html');
                }
                if (!promotionTextThree) {
                    promotionTextThree = RichTextEditor.createEmptyValue();
                } else {
                    promotionTextThree = RichTextEditor.createValueFromString(promotionTextThree, 'html');
                }
                if (!promotionTextFour) {
                    promotionTextFour = RichTextEditor.createEmptyValue();
                } else {
                    promotionTextFour = RichTextEditor.createValueFromString(promotionTextFour, 'html');
                }
                this.setState({
                    acceptableDistance: tenantVariables.acceptableDistance,
                    locationPermissionsBody: tenantVariables.locationPermissionsBody,
                    locationPermissionsHeader: tenantVariables.locationPermissionsHeader,
                    formattedAddress: tenantVariables.formattedAddress,
                    notAcceptableLocationMessage: notAcceptableLocationMessage,
                    notAcceptableLocationHeader: tenantVariables.notAcceptableLocationHeader,
                    canPlayOutside: tenantVariables.canPlayOutside,
                    allowList: tenantVariables.allowList,
                    blockList: tenantVariables.blockList,
                    promotionText: promotionText,
                    promotionTextTwo: promotionTextTwo,
                    promotionTextThree: promotionTextThree,
                    promotionTextFour: promotionTextFour,
                    gdprComplianceMessage: gdprComplianceMessage,
                    advanced: advanced,
                    tenantVariables: tenantVariables
                });
            }
            this.setState({ loading: false });
        });
    }

    handleChange(evt) {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    handleTextChange(evt) {
        const tenantVariablesCopy = { ...this.state.tenantVariables, [evt.target.name]: evt.target.value };
        this.setState({ tenantVariables: tenantVariablesCopy }, () => {
            this.updateTenantVariables();
        });
    }

    selectOption(button) {
        const tenantVariablesCopy = { ...this.state.tenantVariables, [button]: !this.state.tenantVariables[button] };
        this.setState({ tenantVariables: tenantVariablesCopy }, () => {
            this.updateTenantVariables();
        });
    }

    updateTenantVariables() {
        const db = getDatabase();
        const tenantVariablesRef = ref(db, 'tenantVariables');
        update(tenantVariablesRef, this.state.tenantVariables).catch(err => {
            console.error(err);
            showErrorPopup('There was some error!', 'Try again and if the problem persists try logging out and logging back in');
        });
    }

    updateDistance() {
        const tenantVariables = this.state.tenantVariables;
        if (!this.state.acceptableDistance || !this.state.formattedAddress) {
            showInfoPopup('Hold on!', 'Please make sure all the form fields are filled out');
            return;
        }
        if (this.state.acceptableDistance <= 0) {
            showInfoPopup('Hold on!', 'Please make sure distance is greater than 0');
            return;
        }
        const canPlayOutside = this.state.canPlayOutside || false;
        this.setState({ loading: true });
        const vm = this;
        let notAcceptableLocationMessage = vm.state.notAcceptableLocationMessage.toString('html');
        if (notAcceptableLocationMessage === "<p><br></p>") {
            notAcceptableLocationMessage = "";
        }
        geocoder.geocode({ 'address': this.state.formattedAddress }, function (results, status) {
            if (status === 'OK') {
                if (results.length === 1) {
                    const tenantVariablesCopy = tenantVariables;
                    tenantVariablesCopy["formattedAddress"] = vm.state.formattedAddress;
                    tenantVariablesCopy["acceptableDistance"] = vm.state.acceptableDistance;
                    tenantVariablesCopy["longitude"] = results[0].geometry.location.lng();
                    tenantVariablesCopy["latitude"] = results[0].geometry.location.lat();
                    tenantVariablesCopy["locationPermissionsBody"] = vm.state.locationPermissionsBody || "";
                    tenantVariablesCopy["locationPermissionsHeader"] = vm.state.locationPermissionsHeader || "";
                    tenantVariablesCopy["notAcceptableLocationMessage"] = notAcceptableLocationMessage;
                    tenantVariablesCopy["notAcceptableLocationHeader"] = vm.state.notAcceptableLocationHeader || "";
                    tenantVariablesCopy["canPlayOutside"] = canPlayOutside;
                    const db = getDatabase();
                    const tenantVariablesRef = ref(db, 'tenantVariables');
                    update(tenantVariablesRef, tenantVariablesCopy).then(() => {
                        vm.setState({ tenantVariables: tenantVariablesCopy, loading: false });
                        showSuccessPopup('Updates Successful');
                    }).catch(err => {
                        console.log(err);
                        vm.setState({ loading: false });
                        showErrorPopup('There was some error!', 'Try again and if the problem persists try logging out and logging back in');
                    });
                } else {
                    vm.setState({ loading: false });
                    showInfoPopup('Hold on!', 'Too many locations have that address! Add more detail to get only 1 address');
                }
            } else {
                vm.setState({ loading: false });
                showInfoPopup('Hold on!', 'Finding address was not successful because ' + status);
            }
        });
    }

    updateList(listToUpdate) {
        const listName = listToUpdate + 'List';
        let listToSave = this.state[listName] || "";
        listToSave = listToSave.trim().toLowerCase();
        let rejoinedCleanedDomainsEmails = "";
        if (listToSave) {
            const splitList = listToSave.split(/[ ,\s]+/);
            for (const splitListIndex in splitList) {
                const splitItem = splitList[splitListIndex];
                let valid;
                if (splitItem.indexOf('@') === -1) {
                    valid = isValidDomain(splitItem);
                } else {
                    valid = validateEmail(splitItem);
                }
                if (!valid) {
                    showInfoPopup('Hold on!', "One of the items on the block list isn't a valid email or domain. The item causing an issue is: " + splitItem + ".");
                    return;
                }
            }
            rejoinedCleanedDomainsEmails = splitList.join(" ");
        }
        const db = getDatabase();
        const tenantVariablesRef = ref(db, 'tenantVariables');
        update(tenantVariablesRef, { [listName]: rejoinedCleanedDomainsEmails }).then(() => {
            showSuccessPopup('List Updated');
        }).catch(err => {
            console.log(err);
            showErrorPopup('There was some error!', 'Try again and if the problem persists try logging out and logging back in');
        });
    }

    updateOptInText() {
        const tenantVariables = this.state.tenantVariables || {};
        let promotionText = this.state.promotionText.toString('html');
        let promotionTextTwo = this.state.promotionTextTwo.toString('html');
        let promotionTextThree = this.state.promotionTextThree.toString('html');
        let promotionTextFour = this.state.promotionTextFour.toString('html');
        if (promotionText === "<p><br></p>") {
            promotionText = "";
        }
        if (promotionTextTwo === "<p><br></p>") {
            promotionTextTwo = "";
        }
        if (promotionTextThree === "<p><br></p>") {
            promotionTextThree = "";
        }
        if (promotionTextFour === "<p><br></p>") {
            promotionTextFour = "";
        }
        tenantVariables['promotionText'] = promotionText;
        tenantVariables['promotionTextTwo'] = promotionTextTwo;
        tenantVariables['promotionTextThree'] = promotionTextThree;
        tenantVariables['promotionTextFour'] = promotionTextFour;

        this.setState({
            tenantVariables: tenantVariables
        }, () => {
            this.updateTenantVariables();
        });
    }

    handleRichTextChange = (name, text) => {
        this.setState({ [name]: text });
    }

    updateText(variable) {
        const tenantVariablesCopy = this.state.tenantVariables || {};
        let textToUpdate = this.state[variable].toString('html');
        if (textToUpdate === "<p><br></p>") {
            textToUpdate = "";
        }
        tenantVariablesCopy[variable] = textToUpdate;
        this.setState({
            tenantVariables: tenantVariablesCopy,
            loading: false
        }, () => {
            this.updateTenantVariables();
        });
    }

    render() {
        const variables = this.state.tenantVariables || {};
        const collectName = variables.collectName;
        const nameInEmail = variables.nameInEmail;
        const noMandatoryTermsAndConditions = variables.noMandatoryTermsAndConditions;
        const collectCountry = variables.collectCountry;
        const collectZipCode = variables.collectZipCode;
        const collectOptIn = variables.collectOptIn;
        const collectOptInTwo = variables.collectOptInTwo;
        const collectOptInThree = variables.collectOptInThree;
        const collectOptInFour = variables.collectOptInFour;
        const optInDefaultUnchecked = variables.optInDefaultUnchecked;
        const collectOptInTwoNotDefaultCheck = variables.collectOptInTwoNotDefaultCheck;
        const collectOptInThreeNotDefaultCheck = variables.collectOptInThreeNotDefaultCheck;
        const collectOptInFourNotDefaultCheck = variables.collectOptInFourNotDefaultCheck;
        let promotionText = this.state.promotionText || RichTextEditor.createEmptyValue();
        let promotionTextTwo = this.state.promotionTextTwo || RichTextEditor.createEmptyValue();
        let promotionTextThree = this.state.promotionTextThree || RichTextEditor.createEmptyValue();
        let promotionTextFour = this.state.promotionTextFour || RichTextEditor.createEmptyValue();
        if (isObjectEmpty(promotionText)) {
            promotionText = ""
        }
        if (isObjectEmpty(promotionTextTwo)) {
            promotionTextTwo = ""
        }
        if (isObjectEmpty(promotionTextThree)) {
            promotionTextThree = ""
        }
        if (isObjectEmpty(promotionTextFour)) {
            promotionTextFour = ""
        }
        const collectBirthday = variables.collectBirthday;
        const collectPhoneNumber = variables.collectPhoneNumber;
        const formBirthday = variables.formBirthday;
        const optionalPhoneNumber = variables.optionalPhoneNumber;
        const allowAnonymousLogin = variables.allowAnonymousLogin;
        const keepFansLoggedIn = variables.keepFansLoggedIn;
        const doNotCollectEmail = variables.doNotCollectEmail;
        const collectDistance = variables.collectDistance;
        const acceptableDistance = parseFloat(this.state.acceptableDistance);
        const doNotVerifyAmericanZipCode = variables.doNotVerifyAmericanZipCode;
        const verifyCanadianZipCode = variables.verifyCanadianZipCode;
        const collectOtherTextOption = variables.collectOtherTextOption;
        const collectOtherTextOptionPlaceholder = variables.collectOtherTextOptionPlaceholder;
        const gdprCompliance = variables.gdprCompliance;
        let formattedAddress = this.state.formattedAddress;
        let locationPermissionsBody = this.state.locationPermissionsBody;
        let locationPermissionsHeader = this.state.locationPermissionsHeader;
        const sanitizeEmails = variables.sanitizeEmails;
        const canPlayOutside = this.state.canPlayOutside;
        const notAcceptableLocationMessage = this.state.notAcceptableLocationMessage || RichTextEditor.createEmptyValue();
        const notAcceptableLocationHeader = this.state.notAcceptableLocationHeader;
        const gdprComplianceMessage = this.state.gdprComplianceMessage || RichTextEditor.createEmptyValue();
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'LINK_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <div className="container-out container-left-rules" style={{ width: "50%", float: "left" }}>
                        <div className="admin-form-box">
                            <div className="form-group">
                                <label htmlFor="rulesAndRegsText">Collect Name Of Fan</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a MANDATORY name field to the login flow</p>
                                <input type="checkbox" checked={collectName} id="collectName" name="collectName" onClick={() => this.selectOption("collectName")} />
                            </div>
                            {collectName &&
                                <>
                                    <div className="form-group">
                                        <label htmlFor="namePlaceHolderText">Name Placeholder Text</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Use this to inform the fans which name to enter</p>
                                        <input value={variables.namePlaceHolderText} className="form-control" type="text" id="namePlaceHolderText" name="namePlaceHolderText" placeholder="First & Last Name" onChange={this.handleTextChange} />
                                    </div>
                                </>
                            }
                            <div className="form-group">
                                <label htmlFor="collectZipCode">Collect ZipCode</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a MANDATORY zip code field to the login flow</p>
                                <input type="checkbox" checked={collectZipCode} id="collectZipCode" name="collectZipCode" onClick={() => this.selectOption("collectZipCode")} />
                            </div>
                            {collectZipCode &&
                                <div className="form-group">
                                    <label htmlFor="collectZipCodePlaceholder">Placeholder Text</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>This text will show up in the input box</p>
                                    <input placeholder="Zip Code" id="collectZipCodePlaceholder" name="collectZipCodePlaceholder" className="form-control" value={variables.collectZipCodePlaceholder} onChange={this.handleTextChange} />
                                </div>
                            }
                            <div className="form-group">
                                <label htmlFor="collectBirthday">Collect Birthday</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a MANDATORY birthday input field</p>
                                <input type="checkbox" checked={collectBirthday} id="collectBirthday" name="collectBirthday" onClick={() => this.selectOption("collectBirthday")} />
                                <div style={{ display: collectBirthday ? "" : "none", margin: 5 }}>
                                    <div className="form-group" style={{ display: collectBirthday ? "" : "none" }}>
                                        <label htmlFor="allowedAge">Age</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Enter an age that the user must be above to play</p>
                                        <input type="number" id="allowedAge" name="allowedAge" className="form-control" value={variables.allowedAge} onChange={this.handleTextChange} />
                                    </div>
                                    <label className="radio-inline" style={{ marginRight: 5 }}><input type="radio" name="formBirthday" id="formBirthday" onChange={() => this.selectOption("formBirthday")} checked={formBirthday} /> In Form Birthday Input</label>
                                    <label className="radio-inline"><input type="radio" name="formBirthday" id="formBirthday" onChange={() => this.selectOption("formBirthday")} checked={!formBirthday} /> Page Birthday Input</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="collectPhoneNumber">Collect Phone Number</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a MANDATORY phone number field to the login flow</p>
                                <input type="checkbox" checked={collectPhoneNumber} id="collectPhoneNumber" name="collectPhoneNumber" onClick={() => this.selectOption("collectPhoneNumber")} />
                                {collectPhoneNumber &&
                                    <div>
                                        <label className="radio-inline" style={{ marginRight: 5 }}><input type="radio" name="optionalPhoneNumber" id="optionalPhoneNumber" onChange={() => this.selectOption("optionalPhoneNumber")} checked={optionalPhoneNumber} /> Optional</label>
                                        <label className="radio-inline"><input type="radio" name="optionalPhoneNumber" id="optionalPhoneNumber" onChange={() => this.selectOption("optionalPhoneNumber")} checked={!optionalPhoneNumber} /> Mandatory</label>
                                    </div>
                                }
                            </div>

                            <div className="form-group">
                                <label htmlFor="collectOptIn">Turn On Optional Opt-In</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add an optional field to the login flow, enter the text below</p>
                                <input type="checkbox" checked={collectOptIn} id="collectOptIn" name="collectOptIn" onClick={() => this.selectOption("collectOptIn")} />
                            </div>
                            {collectOptIn &&
                                <div className="form-group">
                                    <label htmlFor="promotionText">Opt-In Text</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Text That Shows Next To Opt-In Check Box</p>
                                    <RichTextEditor toolbarConfig={toolbarConfig} id="promotionText" name="promotionText" value={promotionText} onChange={(text) => this.handleRichTextChange('promotionText', text)} placeholder="Opt in to our sweet offer" />
                                    <input type="checkbox" checked={optInDefaultUnchecked} id="optInDefaultUnchecked" name="optInDefaultUnchecked" onClick={() => this.selectOption("optInDefaultUnchecked")} style={{ marginRight: 5 }} />
                                    <label htmlFor="optInDefaultUnchecked">Default Opt-In Not Checked</label>
                                </div>
                            }
                            {(collectOptIn || collectOptInTwo) &&
                                <div className="form-group">
                                    <label htmlFor="collectOptInTwo">Collect Second Opt-In</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add an optional field to the login flow, enter the text below</p>
                                    <input type="checkbox" checked={collectOptInTwo} id="collectOptInTwo" name="collectOptInTwo" onClick={() => this.selectOption("collectOptInTwo")} />
                                </div>
                            }
                            {collectOptInTwo &&
                                <>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Text That Shows Next To Opt-In Check Box</p>
                                    <RichTextEditor toolbarConfig={toolbarConfig} id="promotionTextTwo" name="promotionTextTwo" value={promotionTextTwo} onChange={(text) => this.handleRichTextChange('promotionTextTwo', text)} placeholder="Opt in to our sweet offer" />
                                    <div className="form-group">
                                        <label htmlFor="collectOptInTwoNotDefaultCheck">Default Not Checked</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to have the Opt-In field not default checked</p>
                                        <input type="checkbox" checked={collectOptInTwoNotDefaultCheck} id="collectOptInTwoNotDefaultCheck" name="collectOptInTwoNotDefaultCheck" onClick={() => this.selectOption("collectOptInTwoNotDefaultCheck")} />
                                    </div>
                                </>
                            }
                            {((collectOptIn && collectOptInTwo) || collectOptInThree) &&
                                <>
                                    <div className="form-group">
                                        <label htmlFor="collectOptInTwo">Collect Third Opt-In</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add an optional field to the login flow, enter the text below</p>
                                        <input type="checkbox" checked={collectOptInThree} id="collectOptInThree" name="collectOptInThree" onClick={() => this.selectOption("collectOptInThree")} />
                                    </div>
                                    {collectOptInThree &&
                                        <>
                                            <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Text That Shows Next To Opt-In Check Box</p>
                                            <RichTextEditor toolbarConfig={toolbarConfig} id="promotionTextThree" name="promotionTextThree" value={promotionTextThree} onChange={(text) => this.handleRichTextChange('promotionTextThree', text)} placeholder="Opt in to our sweet offer" />
                                            <div className="form-group">
                                                <label htmlFor="collectOptInThreeNotDefaultCheck">Default Not Checked</label>
                                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to have the Opt-In field not default checked</p>
                                                <input type="checkbox" checked={collectOptInThreeNotDefaultCheck} id="collectOptInThreeNotDefaultCheck" name="collectOptInThreeNotDefaultCheck" onClick={() => this.selectOption("collectOptInThreeNotDefaultCheck")} />
                                            </div>
                                        </>
                                    }
                                </>
                            }
                            {((collectOptIn && collectOptInTwo && collectOptInThree) || collectOptInFour) &&
                                <>
                                    <div className="form-group">
                                        <label htmlFor="collectOptInFour">Collect Fourth Opt-In</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add an optional field to the login flow, enter the text below</p>
                                        <input type="checkbox" checked={collectOptInFour} id="collectOptInFour" name="collectOptInFour" onClick={() => this.selectOption("collectOptInFour")} />
                                    </div>
                                    {collectOptInFour &&
                                        <>
                                            <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Text That Shows Next To Opt-In Check Box</p>
                                            <RichTextEditor toolbarConfig={toolbarConfig} id="promotionTextFour" name="promotionTextFour" value={promotionTextFour} onChange={(text) => this.handleRichTextChange('promotionTextFour', text)} placeholder="Opt in to our sweet offer" />
                                            <div className="form-group">
                                                <label htmlFor="collectOptInFourNotDefaultCheck">Default Not Checked</label>
                                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to have the Opt-In field not default checked</p>
                                                <input type="checkbox" checked={collectOptInFourNotDefaultCheck} id="collectOptInFourNotDefaultCheck" name="collectOptInFourNotDefaultCheck" onClick={() => this.selectOption("collectOptInFourNotDefaultCheck")} />
                                            </div>
                                        </>
                                    }
                                </>
                            }
                            {(collectOptIn || collectOptInTwo || collectOptInThree || collectOptInFour) &&
                                <div className="form-group">
                                    <button className="btn btn-primary btn-lg update-button" style={{ marginTop: 5 }} onClick={() => this.updateOptInText()}><span className="fa fa-arrow-circle-o-up" /> Update Opt-In Text</button>
                                </div>
                            }
                            <div className="form-group">
                                <label htmlFor="collectOtherTextOption">Turn on Other Input</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a mandatory text field to collect other information</p>
                                <input type="checkbox" checked={collectOtherTextOption} id="collectOtherTextOption" name="collectOtherTextOption" onClick={() => this.selectOption("collectOtherTextOption")} />
                            </div>
                            {collectOtherTextOption &&
                                <div className="form-group">
                                    <label htmlFor="collectOtherTextOption">Placeholder Text</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>This text will show up in the input box</p>
                                    <input placeholder="Other" id="collectOtherTextOptionPlaceholder" name="collectOtherTextOptionPlaceholder" className="form-control" value={variables.collectOtherTextOptionPlaceholder} onChange={this.handleTextChange} />
                                </div>
                            }
                            <div className="form-group">
                                <label htmlFor="collectCountry">Collect Country</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a MANDATORY country drop down field to the login flow</p>
                                <input type="checkbox" checked={collectCountry} id="collectCountry" name="collectCountry" onClick={() => this.selectOption("collectCountry")} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="showAdvancedSettings">Advanced</label>
                                <br />
                                <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange} />
                            </div>
                            {this.state.advanced &&
                                <>
                                    <div className="form-group">
                                        <label htmlFor="collectDistance">Turn On/Off Geolocation</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add MANDATORY verification of distance from stadium</p>
                                        <input type="checkbox" checked={collectDistance} id="collectDistance" name="collectDistance" onClick={() => this.selectOption("collectDistance")} />
                                        <span style={{ display: collectDistance ? 'block' : 'none' }}>
                                            <div>
                                                <label htmlFor="formattedAddress" style={{ marginTop: 5, marginBottom: 0 }}>Address</label>
                                                <br />
                                                <input className="form-control" type="text" id="formattedAddress" name="formattedAddress" value={formattedAddress} onChange={this.handleChange} placeholder="Address" />
                                                <br />
                                                <label htmlFor="locationPermissionsHeader" style={{ marginTop: 5, marginBottom: 0 }}>Requesting Location Permissions Header</label>
                                                <br />
                                                <input className="form-control" type="text" id="locationPermissionsHeader" name="locationPermissionsHeader" value={locationPermissionsHeader} onChange={this.handleChange} placeholder="Location Permissions Header" />
                                                <br />
                                                <label htmlFor="locationPermissionsBody" style={{ marginTop: 5, marginBottom: 0 }}>Requesting Location Permissions Body</label>
                                                <br />
                                                <textarea className="form-control" id="locationPermissionsBody" name="locationPermissionsBody" value={locationPermissionsBody} onChange={this.handleChange} placeholder="Location Permissions Body" />
                                                <br />
                                                <label htmlFor="notAcceptableLocationHeader" style={{ marginTop: 5, marginBottom: 0 }}>Not Acceptable Location Header</label>
                                                <br />
                                                <input className="form-control" type="text" id="notAcceptableLocationHeader" name="notAcceptableLocationHeader" value={notAcceptableLocationHeader} onChange={this.handleChange} placeholder="Not Acceptable Location Header" />
                                                <br />
                                                <label htmlFor="notAcceptableLocationMessage" style={{ marginTop: 5, marginBottom: 0 }}>Not Acceptable Location Message</label>
                                                <br />
                                                <RichTextEditor toolbarConfig={toolbarConfig} id="notAcceptableLocationMessage" name="notAcceptableLocationMessage" value={notAcceptableLocationMessage} onChange={(text) => this.handleRichTextChange("notAcceptableLocationMessage", text)} placeholder="Not Acceptable Location Message" />
                                                <label htmlFor="acceptableDistance" style={{ marginTop: 5, marginBottom: 0 }}>Distance (Miles)</label> <input type="checkbox" onClick={() => this.setState({ canPlayOutside: false })} checked={!canPlayOutside} /> Fans Can Play Within <input onClick={() => this.setState({ canPlayOutside: true })} type="checkbox" checked={canPlayOutside} /> Fans Can Play Outside
                                                <br />
                                                <input className="form-control" type="number" id="acceptableDistance" step="0.1" name="acceptableDistance" min="0" value={acceptableDistance} onChange={this.handleChange} placeholder="" />
                                            </div>
                                            <button className="btn btn-primary btn-lg update-button" style={{ marginTop: 5 }} onClick={() => this.updateDistance()}><span className="fa fa-arrow-circle-o-up" /> Update Distance Variables</button>
                                        </span>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="doNotCollectEmail">DO NOT Collect Email</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to remove collecting of email. ONLY CHECK THIS IF YOU KNOW WHAT YOU ARE DOING</p>
                                        <input type="checkbox" checked={doNotCollectEmail} id="doNotCollectEmail" name="doNotCollectEmail" onClick={() => this.selectOption("doNotCollectEmail")} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="keepFansLoggedIn">Keep Fans Logged In?</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to keep fans logged in so they will not have to keep filling in the login form</p>
                                        <input type="checkbox" checked={keepFansLoggedIn} id="keepFansLoggedIn" name="keepFansLoggedIn" onClick={() => this.selectOption("keepFansLoggedIn")} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="noMandatoryTermsAndConditions">Turn OFF Mandatory Terms And Conditions</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to remove the MANDATORY confirm rules and regs checkbox<br />Rules and Regs Text and Link come from the <a href="/setuprulesandregs"><u>Rules and Regs Tab</u></a></p>
                                        <input type="checkbox" checked={noMandatoryTermsAndConditions} id="noMandatoryTermsAndConditions" name="noMandatoryTermsAndConditions" onClick={() => this.selectOption("noMandatoryTermsAndConditions")} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="sanitizeEmails">Sanitize Emails</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to map accounts with the same sanitized email to the same account<br />(could lead to issues with users having the same email address)</p>
                                        <input type="checkbox" checked={sanitizeEmails} id="sanitizeEmails" name="sanitizeEmails" onClick={() => this.selectOption("sanitizeEmails")} />
                                    </div>
                                    {collectZipCode &&
                                        <div className="form-group">
                                            <label htmlFor="doNotVerifyAmericanZipCode">Do Not Verify American Zip Code?</label>
                                            <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Do not verify American Zip Code. Check this only if the zip codes you are collecting are outside the U.S.</p>
                                            <input type="checkbox" checked={doNotVerifyAmericanZipCode} id="doNotVerifyAmericanZipCode" name="doNotVerifyAmericanZipCode" onClick={() => this.selectOption("doNotVerifyAmericanZipCode")} />
                                        </div>
                                    }
                                    {collectZipCode && doNotVerifyAmericanZipCode &&
                                        <div className="form-group">
                                            <label htmlFor="verifyCanadianZipCode">Verify Canadian Zip Code?</label>
                                            <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Verify Canadian Zip Code. Check this only if the zip codes you are collecting are from Canada</p>
                                            <input type="checkbox" checked={verifyCanadianZipCode} id="verifyCanadianZipCode" name="verifyCanadianZipCode" onClick={() => this.selectOption("verifyCanadianZipCode")} />
                                        </div>
                                    }
                                    <div className="form-group">
                                        <label htmlFor="blockList">Block List Emails/Domains</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Enter any emails or domains that should be blocked from logging in.</p>
                                        <textarea className="form-control" id="blockList" name="blockList" value={this.state.blockList} onChange={this.handleChange} />
                                        <button className="btn btn-primary btn-lg update-button" style={{ marginTop: 5 }} onClick={() => this.updateList('block')}><span className="fa fa-arrow-circle-o-up" /> Update Block List</button>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="allowList">Allow List Emails/Domains</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Enter any emails or domains that should be allowed to login, all other emails/domains will be blocked. This will SUPERSEDE any emails/domains on the block list and let them play</p>
                                        <textarea className="form-control" id="allowList" name="allowList" value={this.state.allowList} onChange={this.handleChange} />
                                        <button className="btn btn-primary btn-lg update-button" style={{ marginTop: 5 }} onClick={() => this.updateList('allow')}><span className="fa fa-arrow-circle-o-up" /> Update Allow List</button>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="gdprCompliance">GDPR Compliance</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to force the user to go through the GDPR flow</p>
                                        <input type="checkbox" checked={gdprCompliance} id="gdprCompliance" name="gdprCompliance" onClick={() => this.selectOption("gdprCompliance")} />
                                    </div>
                                    {gdprCompliance &&
                                        <>
                                            <label htmlFor="gdprComplianceHeader" style={{ marginTop: 5, marginBottom: 0 }}>GDPR Pop Up Header</label>
                                            <br />
                                            <input className="form-control" type="text" id="gdprComplianceHeader" name="gdprComplianceHeader" value={variables.gdprComplianceHeader} onChange={this.handleTextChange} placeholder="Make Sure To Read The Rules!" />
                                            <br />
                                            <label htmlFor="gdprComplianceMessage" style={{ marginTop: 5, marginBottom: 0 }}>GDPR Pop Up Message</label>
                                            <br />
                                            <RichTextEditor toolbarConfig={toolbarConfig} id="gdprComplianceMessage" name="gdprComplianceMessage" value={gdprComplianceMessage} onChange={(text) => this.handleRichTextChange('gdprComplianceMessage', text)} placeholder="" />
                                            <button className="btn btn-primary btn-lg update-button" style={{ marginTop: 5 }} onClick={() => this.updateText("gdprComplianceMessage")}><span className="fa fa-arrow-circle-o-up" /> Update GDPR Compliance Messaging</button>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                    <div className="container-out mobile-hide" style={{ width: '45%', float: 'right', marginRight: '20px' }}>
                        <div className="admin-form-box" style={{ border: '1px solid black', backgroundColor: 'white' }}>
                            <p style={{ fontSize: '20px', fontWeight: 'bold' }}>What Data Should I Gather?</p>
                            <p className="text-muted2">Think carefully about what you want to gather from your fan base. The more you ask for, the fewer people will be willing to go through the whole logging in process.
                                Balance that with useful parameters that you can use to help your fans get more value from your experience.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SetUpLoginVariables;
