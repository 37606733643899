import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import { getDatabase, ref, onValue, off, get, query, orderByChild, startAt, endAt, limitToLast, equalTo } from 'firebase/database';
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';
import { cleanCSVText, valueDoesExist, findFirstAndLastName, convertTimeStampToHumanReadable, cleanFirebaseText } from '../utils/HelpfulFunction';
import DatePicker from '../utils/DatePicker';

class AdminHome extends Component {
    constructor(props) {
        super(props);
        const todaysDateObject = new Date();
        todaysDateObject.setDate(todaysDateObject.getDate() - 30);
        todaysDateObject.setHours(0, 0, 0, 0); // Sets the time to start of the day (midnight)
        const endDateObject = new Date();
        endDateObject.setHours(23, 59, 59, 999); // Sets the time to end of the day (11:59 pm)
        this.state = {
            games: 0,
            users: 0,
            itemsPerPage: 10,
            itemsToShow: 10,
            uniqueGamesUsers: 0,
            loading: false,
            players: 0,
            gameUsers: 0,
            prizesWon: 0,
            lastGame: {},
            uniqueGameUsers: false,
            gamesToShowList: [],
            searchStart: todaysDateObject,
            searchEnd: endDateObject
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(evt) {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.name]: value
        });
    }

    componentDidMount() {
        this.getGames(this.state.searchStart.getTime(), this.state.searchEnd.getTime());
        this.getUsers(this.state.searchStart.getTime(), this.state.searchEnd.getTime());

        const db = getDatabase();
        this.formQuestionsRef = onValue(ref(db, 'formQuestions'), snapshot => {
            this.setState({ formQuestions: snapshot.val() });
        });
        this.tenantVariablesRef = onValue(ref(db, 'tenantVariables'), snapshot => {
            this.setState({ tenantVariables: snapshot.val() });
        });
    }

    componentWillUnmount() {
        const db = getDatabase();
        off(ref(db, 'formQuestions'), 'value', this.formQuestionsRef);
        off(ref(db, 'tenantVariables'), 'value', this.tenantVariablesRef);
    }

    async getGames(start = 0, end) {
        const vm = this;
        const db = getDatabase();
        let queryString = query(ref(db, 'gamesList'), orderByChild('timeStamp'), startAt(start));
        let currentGame = null;
        let currentGameObject = await get(ref(db, 'currentGame')).then(snapshot => {
            let gameTemp = snapshot.val();
            if (gameTemp && gameTemp.timeStamp > start) {
                return gameTemp;
            } else {
                return null;
            }
        });
        if (end) {
            queryString = query(queryString, endAt(end));
            if (currentGameObject && currentGameObject.timeStamp > end) {
                currentGameObject = null;
            }
        }
        currentGame = currentGameObject;
        let currentGamePrizes = 0;
        let currentGameUsers = 0;
        let gamesCount = 0;
        if (currentGame) {
            try {
                currentGameUsers = await get(query(ref(db, "userGameHistory"), orderByChild(currentGame.id), equalTo(currentGame.id))).then(snapshot => snapshot.numChildren());
            } catch (error) {
                console.error("Error fetching game data:", error);
            }
            console.log("currentGameUsers: ", currentGameUsers)
            gamesCount++;
            currentGame.users = currentGameUsers;
            currentGame.currentGame = true;
        }
        get(queryString).then(snapshot => {
            let gameUsersCount = currentGameUsers;
            let gamesArray = [];
            let index = 0;
            snapshot.forEach(data => {
                index++;
                gameUsersCount += data.val().users || 0;
                if (snapshot.size - vm.state.itemsPerPage < index) {
                    gamesArray.unshift(data.val());
                }
            });
            if (currentGame) {
                gamesArray.unshift(currentGame);
            }
            vm.setState({
                loading: false,
                games: gamesCount + snapshot.size,
                gameUsers: gameUsersCount,
                gamesToShowList: gamesArray
            });
        });
    }

    async getUsers(start = 0, end, returnUsers = false) {
        const vm = this;
        const db = getDatabase();
        let queryString = query(ref(db, 'users'), orderByChild('signUpTime'));
        if (start) {
            queryString = query(queryString, startAt(start));
        }
        if (end) {
            queryString = query(queryString, endAt(end));
        }
        if (returnUsers) {
            return await get(queryString).then(snapshot => {
                if (snapshot.exists()) {
                    return snapshot.val();
                } else {
                    return {};
                }
            });
        } else {
            get(queryString).then(snapshot => {
                vm.setState({
                    loading: false,
                    users: snapshot.size
                });
            });
        }
    }

    async getUserData() {
        this.setState({
            loading: true
        });
        let searchStart = this.state.searchStart || 0;
        let searchEnd = this.state.searchEnd;
        if (typeof searchStart === "object") {
            searchStart = searchStart.getTime();
        }
        if (searchEnd && typeof searchEnd === "object") {
            searchEnd = searchEnd.getTime();
        }
        let csv = process.env.REACT_APP_CLEAN_CLIENT_NAME || process.env.REACT_APP_FIREBASE_PROJECT_ID;
        const date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)).toISOString().split("T")[0];
        csv += ",Polling\n,\n";
        csv += "Date Data Downloaded," + date_got + "\n";
        csv += "From," + (searchStart ? convertTimeStampToHumanReadable(searchStart) : "") + "\n";
        csv += "To," + (searchEnd ? convertTimeStampToHumanReadable(searchEnd) : "") + "\n,\n";
        csv += 'Email,First Name,Last Name,Phone Number,Zip Code,Birthday,Country,Opt-In,Opt-In 2,Opt-In 3,Opt-In 4,Signed Up';
        const newColumnsArray = [];

        if (this.state.formQuestions) {
            for (const i in this.state.formQuestions) {
                const formQuestion = this.state.formQuestions[i];
                newColumnsArray.push(formQuestion.id);
                csv += ("," + cleanCSVText(formQuestion.text || ""));
            }
            if (this.state.tenantVariables.otherResponse) {
                csv += (",Other");
            }
        }

        csv += "\n";
        const vm = this;
        let timeFrameUsers = await this.getUsers(searchStart, searchEnd, true);
        for (let timeFrameUserIndex in timeFrameUsers) {
            let user = timeFrameUsers[timeFrameUserIndex];
            let { firstName, secondName } = findFirstAndLastName(user.name);
            csv += (user.email || user.uid || "") + ",";
            csv += (cleanCSVText(firstName) || "") + ",";
            csv += (cleanCSVText(secondName) || "") + ",";
            csv += (user.phoneNumber || "") + ",";
            csv += (cleanCSVText(user.zipCode) || "") + ",";
            csv += (user.birthday || "") + ",";
            csv += (user.country || "") + ",";
            csv += (user.optIn || "") + ",";
            csv += (user.optInTwo || "") + ",";
            csv += (user.optInThree || "") + ",";
            csv += (user.optInFour || "") + ",";
            csv += (user.signUpTime ? convertTimeStampToHumanReadable(user.signUpTime) : "");
            for (const indexOfColumnArray in newColumnsArray) {
                const columnHeaderName = newColumnsArray[indexOfColumnArray];
                csv += ",";
                if (user && user.form_responses && (user.form_responses[columnHeaderName] === false || user.form_responses[columnHeaderName] === true)) {
                    csv += (user.form_responses[columnHeaderName] || "");
                } else {
                    csv += (user[columnHeaderName] || "");
                }
            }
            if (vm.state.tenantVariables.otherResponse) {
                csv += ",";
                csv += (cleanCSVText(user.otherInput) || "");
            }
            csv += "\n";
        }
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        let cleanString = (searchStart ? "_" + convertTimeStampToHumanReadable(searchStart) : "") + (searchEnd ? "_" + convertTimeStampToHumanReadable(searchEnd) : "");
        cleanString = "Polling_accounts_made" + cleanString.replace(/[|&;$%@"<>()+,]/g, "").toLowerCase() + ".csv";
        hiddenElement.download = cleanString;
        hiddenElement.click();
        vm.setState({
            loading: false
        });
    }

    async downloadIntenseGameData(game) {
        this.setState({
            loading: true
        });
        const db = getDatabase();
        let csv = process.env.REACT_APP_FAN_LINK + ',' + 'Polling\n""\n';
        let date_downloaded = new Date((new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)).toISOString().split("T")[0];
        const gameAnswers = await get(ref(db, `userAnswersHistory/${game.id}`)).then(snapshot => {
            if (snapshot.exists()) {
                return snapshot.val();
            } else {
                return [];
            }
        });
        csv += 'Date Data Downloaded,' + date_downloaded + '\n';
        csv += 'Game Name,' + game.gameName + '\n';
        csv += 'Start Time,' + (game.scheduleInfo ? convertTimeStampToHumanReadable(game.scheduleInfo.performAt) : "") + '\n';
        csv += 'End Time,' + (game.scheduleInfo ? convertTimeStampToHumanReadable(game.scheduleInfo.endAt) : "") + '\n';
        csv += '\n""\n';
        csv += 'Email,First Name,Last Name,Phone Number,Zip Code,Birthday,Country,Other,Opt-In,Opt-In 2,Opt-In 3,Opt-In 4,Answer,Answer Time';
        const newColumnsArray = [];
        if (this.state.formQuestions) {
            for (const i in this.state.formQuestions) {
                const formQuestion = this.state.formQuestions[i];
                newColumnsArray.push(formQuestion.id);
                csv += ("," + cleanCSVText(formQuestion.text || ""));
            }
            if (this.state.tenantVariables.otherResponse) {
                csv += (",Other");
            }
        }

        csv += "\n";

        const usersData = await get(ref(db, 'users')).then(usersSnapshot => {
            return usersSnapshot.val();
        });
        const vm = this;
        for (const userIndex in gameAnswers) {
            const userInfo = gameAnswers[userIndex];
            const moreUserData = usersData[userInfo.uid];
            let { firstName, secondName } = findFirstAndLastName(moreUserData.name);
            csv += (moreUserData.email || moreUserData.uid || "")
                + ',' + (cleanCSVText(firstName) || "")
                + ',' + (cleanCSVText(secondName) || "")
                + ',' + (moreUserData.phoneNumber || "")
                + "," + (cleanCSVText(moreUserData.zipCode) || "")
                + ',' + (moreUserData.birthday || "")
                + ',' + (moreUserData.country || "")
                + ',' + (cleanCSVText(moreUserData.otherInput) || "")
                + ',' + (moreUserData.optIn || "")
                + ',' + (moreUserData.optInTwo || "")
                + ',' + (moreUserData.optInThree || "")
                + ',' + (moreUserData.optInFour || "")
                + ',' + cleanCSVText((valueDoesExist(userInfo.answerText) ? userInfo.answerText : ""))
                + ',' + convertTimeStampToHumanReadable(userInfo.timeStamp);
            for (const indexOfColumnArray in newColumnsArray) {
                const columnHeaderName = newColumnsArray[indexOfColumnArray];
                csv += ",";
                if (moreUserData && moreUserData.form_responses && (moreUserData.form_responses[columnHeaderName] === false || moreUserData.form_responses[columnHeaderName] === true)) {
                    csv += (moreUserData.form_responses[columnHeaderName] || "");
                } else {
                    csv += (userInfo[cleanFirebaseText(columnHeaderName)] || "");
                }
            }
            if (vm.state.tenantVariables.otherResponse) {
                csv += ",";
                csv += (cleanCSVText(moreUserData.otherInput) || "");
            }
            csv += '\n';
        }
        let hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        let cleanString = game.gameName.replace(/[|&;$%@"<>()+,. ]/g, "").toLowerCase();
        hiddenElement.download = "polling_game_data_" + cleanString + ".csv";
        hiddenElement.click();
        this.setState({ loading: false });
    }

    searchData() {
        let searchStart = this.state.searchStart;
        let searchEnd = this.state.searchEnd;
        if (searchStart) {
            searchStart = searchStart.getTime();
        }
        if (searchEnd) {
            searchEnd = searchEnd.getTime();
        }
        this.setState({
            loading: true,
            itemsPerPage: this.state.itemsPerPage
        }, () => {
            this.getGames(searchStart, searchEnd);
            this.getUsers(searchStart, searchEnd);
        });
    }

    onNextPage() {
        const vm = this;
        const newAmountToShow = this.state.itemsToShow + this.state.itemsPerPage;
        let searchStart = this.state.searchStart;
        let searchEnd = this.state.searchEnd;
        if (searchStart) {
            searchStart = searchStart.getTime();
        }
        if (searchEnd) {
            searchEnd = searchEnd.getTime();
        }
        const db = getDatabase();
        let queryString = query(ref(db, 'gamesList'), orderByChild('timeStamp'), limitToLast(newAmountToShow), startAt(searchStart));
        if (searchEnd) {
            queryString = query(queryString, endAt(searchEnd));
        }
        get(queryString).then(snapshot => {
            let gamesArray = [];
            snapshot.forEach(data => {
                gamesArray.unshift(data.val());
            });
            vm.setState({
                itemsToShow: newAmountToShow,
                gamesToShowList: gamesArray
            });
        });
    }

    render() {
        const totalUsers = this.state.users || 0;
        const gameUsers = this.state.gameUsers || 0;
        const prizesWon = this.state.prizesWon || 0;
        const fan_side_link = process.env.REACT_APP_FAN_LINK || "";
        const scoreboard_horizontal_link = process.env.REACT_APP_SCOREBOARD_HORIZONTAL_LINK || "";
        const scoreboard_vertical_link = process.env.REACT_APP_SCOREBOARD_VERTICAL_LINK || "";

        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <div className="row" style={{ width: '100%' }}>
                        <div className="col-md-11">
                            <p className="admin-header-text" style={{ marginTop: '10px' }}>Game link: <span style={{ fontSize: '20px' }}>{fan_side_link}</span><br />
                                Scoreboard Top Image Link: <span style={{ fontSize: '20px' }}>{scoreboard_vertical_link}</span><br />
                                Scoreboard Left Image link: <span style={{ fontSize: '20px' }}>{scoreboard_horizontal_link}</span>
                            </p>
                        </div>
                        <div className="col-md-1">
                            <p style={{ color: "black" }}>
                                v{process.env.REACT_APP_VERSION}
                            </p>
                        </div>
                    </div>
                    <form>
                        <div className="row" style={{ marginLeft: 20 }}>
                            <div className="d-flex align-items-center gap-2">
                                <div className="form-group" style={{ marginRight: 10 }}>
                                    <DatePicker
                                        isClearable
                                        selected={this.state.searchStart}
                                        onChange={date => {
                                            if (date) date.setHours(0, 0, 0, 0);
                                            this.setState({ searchStart: date });
                                        }}
                                    />
                                </div>

                                <div className="form-group" style={{ marginRight: 10 }}>
                                    <DatePicker
                                        isClearable
                                        selected={this.state.searchEnd}
                                        onChange={date => {
                                            if (date) date.setHours(23, 59, 59, 999);
                                            this.setState({ searchEnd: date });
                                        }}
                                    />
                                </div>

                                <div>
                                    <button className="btn btn-primary btn-admin" type="button" onClick={() => this.searchData()}>
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="admin-grid-container four-columns" style={{ marginTop: 20 }}>
                        <div className="card card-styles" style={{ backgroundColor: "black" }}>
                            <div className="card-body">
                                <blockquote className="card-bodyquote" style={{ margin: 0 }}>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <p style={{ color: "white", fontSize: 50, margin: 0, textAlign: 'left' }}>{totalUsers}</p>
                                            <span style={{ color: "#f8f8ff", fontSize: 20, textAlign: 'left', display: 'block' }}>Accounts Created</span>
                                        </div>
                                        <div className="col-md-4" style={{ fontSize: 40, alignSelf: "center", textAlign: 'left' }}>
                                            <i className="fa fa-arrow-circle-o-down" aria-hidden="true" style={{ color: "white", cursor: "pointer" }} onClick={() => this.getUserData()} />
                                        </div>
                                    </div>
                                </blockquote>
                            </div>
                        </div>
                        <div className="card card-styles" style={{ backgroundColor: "black" }}>
                            <div className="card-body">
                                <blockquote className="card-bodyquote" style={{ margin: 0 }}>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <p style={{ color: "white", fontSize: 50, margin: 0, textAlign: 'left' }}>{gameUsers}</p>
                                            <span style={{ color: "#f8f8ff", fontSize: 20, textAlign: 'left', display: 'block' }}>Game Users</span>
                                        </div>
                                    </div>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                    <div style={{ margin: 20 }}>
                        {this.state.gamesToShowList && this.state.gamesToShowList.length > 0 &&
                            <>
                                {
                                    this.state.gamesToShowList.map(function (item, index) {
                                        return (
                                            <div key={index} className="card card-styles text-center" style={{ marginBottom: 10 }}>
                                                <div className="card-body" style={{ boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px 0px" }}>
                                                    <div className="row" style={{ alignItems: 'center' }}>
                                                        <div className="col-md-2">
                                                            <span style={{ color: 'black', fontSize: 14 }}>{convertTimeStampToHumanReadable(item.timeStamp)}</span>
                                                        </div>
                                                        <div className="col-md-3" style={{ fontSize: 30 }}>
                                                            {item.gameName}
                                                        </div>
                                                        <div className="col-md-2 ms-auto" style={{ textAlign: 'right' }}>
                                                            <span style={{ fontSize: 25 }}>{item.users || 0}</span>
                                                            <span style={{ color: 'black', fontSize: 15, marginRight: 15 }}>Played</span>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div style={{ fontSize: 20, justifyContent: 'center' }}>
                                                                {item.prizesWon !== null && !item.currentGame &&
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => this.downloadIntenseGameData(item)}
                                                                        className="export-button-styles btn btn-primary btn-lg download-button mobile-hide"
                                                                        style={{ padding: '5px 10px', whiteSpace: 'nowrap' }}>
                                                                        <span className="fa fa-arrow-circle-down" />
                                                                        Download Game Data
                                                                    </button>
                                                                }
                                                                {item.currentGame &&
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => window.location.href = '/setupgame'}
                                                                        className="btn btn-primary btn-lg download-button mobile-hide"
                                                                        style={{ padding: '5px 10px', whiteSpace: 'nowrap' }}>
                                                                        Go To Current Game
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }, this)
                                }
                                {this.state.games > this.state.gamesToShowList.length &&
                                    <button className="btn btn-primary" onClick={() => this.onNextPage()}>
                                        More
                                    </button>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminHome;
