import RichTextEditor from "html-verify-react-rte";

export const cleanCSVText = (textToClean) => {
    if (textToClean && typeof textToClean === "string"){
        return textToClean.replace(/,/g, '')
    } else {
        return textToClean;
    }
}

export const cleanFirebaseText = (textToClean) => {
    if (textToClean && typeof textToClean === "string"){
        textToClean = textToClean.replace(".", "");
        textToClean = textToClean.replace("$", "");
        textToClean = textToClean.replace("#", "");
        textToClean = textToClean.replace("[", "");
        textToClean = textToClean.replace("]", "");
        textToClean = textToClean.replace("/", "");
        return textToClean
    }
    return textToClean;
}

export const valueDoesExist = (value) => {
    return !(value === false || value === "" || value === null || value === undefined);
}

export const validUrl = (str) => {
    try {
        // Try to create a new URL object
        new URL(str);
        return true;
    } catch (err) {
        // If an error is thrown, the URL is not valid
        return false;
    }
}

export const findFirstAndLastName = (nameString) => {
    let firstName = "";
    let secondName = "";
    if(nameString && nameString.trim()) {
        nameString = nameString.trim();
        if (nameString.indexOf(" ") !== -1) {
            let splitName = nameString.split(" ");
            firstName = splitName[0];
            if (splitName.length >= 2) {
                secondName = splitName[splitName.length - 1]
            }
        }
    }
    return {firstName: firstName, secondName: secondName}
}

export const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const isObjectEmpty = (obj) =>  {
    for(let prop in obj) {
        if(obj.hasOwnProperty(prop)) {
            return false;
        }
    }
    return JSON.stringify(obj) === JSON.stringify({});
}

export const validateEmail = (email) => {
    let re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(email);
}

function toStandardTime(value) {
    if (value !== null && value !== undefined){ //If value is passed in
        if(value.indexOf('AM') > -1 || value.indexOf('PM') > -1){ //If time is already in standard time then don't format.
            return value;
        }
        else {
            if(value.length === 8){ //If value is the expected length for military time then process to standard time.
                let hour = parseInt(value.substring ( 0,2 )); //Extract hour
                const minutes = value.substring ( 3,5 ); //Extract minutes
                let identifier = 'AM'; //Initialize AM PM identifier

                if(hour === 12){ //If hour is 12 then should set AM PM identifier to PM
                    identifier = 'PM';
                }
                if(hour === 0){ //If hour is 0 then set to 12 for standard time 12 AM
                    hour=12;
                }
                if(hour > 12){ //If hour is greater than 12 then convert to standard 12 hour format and set the AM PM identifier to PM
                    hour = hour - 12;
                    identifier='PM';
                }
                return hour.toString() + ':' + minutes + ' ' + identifier; //Return the constructed standard time
            }
            else { //If value is not the expected length than just return the value as is
                return value;
            }
        }
    }
}

function checkIfTimeStampIsInSecondsAndConvert(timeStamp){
    //check if timeStamp is in seconds or milliseconds
    //Its 2020 so for now we can assume milliseconds if timestamp has 13 or more digits
    //This should work until the year 33658 so thats good
    //is milliseconds and javascript prefers milliseconds
    let stringTimeStampLength = 0;
    if(typeof timeStamp !== "string"){
        stringTimeStampLength = timeStamp.toString().length;
    } else {
        stringTimeStampLength = timeStamp.length;
        timeStamp = parseInt(timeStamp);
    }
    if(stringTimeStampLength < 13){
        //is seconds convert to milliseconds
        return new Date(timeStamp*1000)
    } else {
        return new Date(timeStamp)
    }
}

export const getSnapshotFromEndpoint = (endpoint, appDatabasePrimaryFunctions) => {
    return appDatabasePrimaryFunctions.ref(endpoint).once('value').then(function(snapshot){
        return snapshot;
    })
}

export const convertTimeStampToHumanReadable = (timeStamp) => {
    if(!timeStamp){
        return null
    }
    const dateToConvert = checkIfTimeStampIsInSecondsAndConvert(timeStamp);
    const formattedTimeSplit = dateToConvert.toString().split(" ");
    formattedTimeSplit[4] = toStandardTime(formattedTimeSplit[4]);
    //remove bullshit returned by date
    formattedTimeSplit.splice(5,4);
    //get short timezone
    formattedTimeSplit.push(dateToConvert.toLocaleString('en', {timeZoneName:'short'}).split(' ').pop());
    return formattedTimeSplit.join(" ");
};

export const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
}

export const returnCorrectRichTextObject = (text) => {
    if(!text){
        return RichTextEditor.createEmptyValue();
    }
    if(typeof text === 'string') {
        text = RichTextEditor.createValueFromString(text, 'html')
    }
    return text;
}

export const readyRichTextForSubmit = (text) => {
    text = text || RichTextEditor.createEmptyValue();
    text = text.toString('html').replace(/<a /g, '<a rel="noopener noreferrer"');
    if(text === "<p><br></p>"){
        text = "";
    }
    return text
}
